.tool-box{
    margin: 1% 2%;
}
.title-box-lowlift{
    margin-top: 1%;
}

.Tool-Box-1, .Tool-Box-2{
    display: flex;
    flex-direction: column;
    border-top: 4px solid #ed1c2c;
    background-color: rgba(238, 240, 242, 0.45);
   
}

.Tool-Box-1{
    padding: 1% 6%;
    row-gap: 15px;
}
.Tool-Box-2{
    padding: 1% 1%;
}




.image-container-2{
position: relative;
height: 100%;
object-fit: contain;
transform: rotate(1deg);
}

.power-image-tool{
    position: relative;
    margin-left: 15%;
    width: 60%;
}

@media (max-width: 600px) {
    .power-image-tool{
        width: 100%;
        margin: 0;
    }
    
   
}

.EWT,.LWT,.EWT2,.LWT2, .High-SST{
    position: absolute;
    z-index: 2;
}

.EWT{
    bottom: 42%;
    left: 14%;
    transform: rotate(-2deg);
}
.LWT{
    bottom:-1%;
    left: 20%;
    transform: rotate(-2deg);
}

.EWT2{
    bottom: 20%;
    right: 29%;
    transform: rotate(-2deg);
}
.LWT2{
    top: 10%;
    right: 35%;
    transform: rotate(-2deg);
}
.High-SST{
    bottom: 5%;
    right: 5%;
}


@media (max-width: 600px) {
 
    .High-SST{
        left: 2%;
        top: 12%;
    }
    .EWT{
        left: 4%;
    }
    .EWT2{
        bottom: 3%;
        right: 10%;
    }
    .LWT2{
        right: 15%;
}
}



.Tool-Box-3, .Tool-Box-4{
    border-top: 4px solid #ed1c2c;
    background-color: #ECF0F5;
  
}

.Tool-Box-3{
    padding: 2% 5%;
    display: flex;
    flex-direction: row;

}
.Tool-Box-4{
    display: flex;
    flex-direction: column;
    padding: 2% 3.5% 2% 3.5% 
}

.Tool-Box-3-4{
margin-top: 2%;
}

@media (min-height: 720px) {
   
    .Tool-Box-3-4{
        margin-top: 2%;
    }
    .Tool-Box-3{
        padding: 3% 5%;
    }
    .Tool-Box-4{
        padding: 3%;
    }
    .tool-box{
        margin: 3% 2% 2% 2%;
    }
    
}

