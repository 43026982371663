
.ScreenBox{
    height: 100vh;
    height: 100svh;
    background-image: linear-gradient(110deg, #F0F2F2 45%, #ED1C2C 55%);
}


.ScreenBox2{
    height: 100vh;
    height: 100svh;

    /* background-image: linear-gradient(110deg, #F0F2F2 45%, #ED1C2C 55%); */
}

.ScreenBox3{
    height: 100vh;
    height: 100svh;
    background-color: #F2F4F7;
    /* background-image: linear-gradient(to bottom, #F0F2F2 25%, #ED1C2C 75%); */
}

@media (max-width: 950px) {
    .ScreenBox {
        overflow: auto;
        background-image: linear-gradient(to bottom, #F0F2F2 45%, #ED1C2C 55%);
    }
}


.main{
    margin: 2%;
    padding: 2%;
    background-color: #B0000F;
}


.main2{
    margin: 1%;
    padding: 1% 1% 0 1%;
    background-color: #B0000F;
}



@media (max-width: 950px) {
    .main2{
        margin: 1%;
        padding: 2% 3% 9% 3% ;
        overflow: auto;
        background-color: #B0000F;
    }
    
  }


h4{
    font-family: 'Merriweather', serif; 
    color: black;
    font-weight: 400;
    font-size: calc(0.7rem + 0.3vw);
    }



.headingStyle{
    font-family: 'Merriweather', serif; 
    text-align: center;
    color: white;
    font-size: calc(1rem + 0.2vw);
}



.topicTypo{
    font-family: 'Merriweather', serif; 
    font-size: calc(0.8rem + 0.1vw);
    font-weight: 400;
    color: white;
}



h3{
    font-family: 'Merriweather', serif; 
    font-size: calc(0.8rem + 0.1vw);
    color: black;
    font-weight: 400;
    margin-bottom: 0.2rem;

}




h5{
    font-family: 'Merriweather', serif; 
    font-size: calc(0.75rem + 0.1vw);
    color: black;
    font-weight: 500;

}


h6{
    font-family: 'Merriweather', serif; 
    font-size: calc(0.70rem + 0.1vw);
    color: black;
    font-weight: 400;
}



.selectStyle{
    font-family: 'Merriweather', serif;
    font-weight: 500;
    font-size: calc(0.85rem + 0.06vw);
}

::-webkit-scrollbar {
    width: 5px; /* Adjust the width as needed */
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px; /* Adjust the border-radius as needed */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }