.Value-Page-Main-Box{
        margin: 1% 2%;
        display: flex;
        flex-direction: row;
}

.Value-Page-Box-1{
        border-top: 4px solid #ed1c2c;
        background-color: #f7f8f9;
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding: 1% 2%;
        height: 30rem;
}

.Value-page-box-2{
        margin-left: 2%;
        border-top: 4px solid #ed1c2c;
        background-color: #f7f8f9;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;
        padding: 3% 1% 0% 1%;

}

.Value-Button{
        width: 100%;
        padding:2% 3%;
        border-radius: 7px;
        margin-top: 1%;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

@media (min-height: 720px) {
        .Value-Page-Main-Box{
                margin: 2% 2%;
                
        }
        .Value-Page-Box-1{
                height: 38rem;
        }
        .Value-page-box-2{
                padding: 5% 0 2% 0;
        }
}