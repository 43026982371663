.title-box-ofc{
    margin-top: 1%;
}
.ofc-page-box{
    margin: 0 2%;
}

.power-image-tool-ofc{
position: relative;
width: 65%;  
z-index: 1;
transform: rotate(2deg);
}

@media (max-width: 600px) {
.power-image-tool-ofc{
    width: 100%;
}
}
.OFC-Features-Container{
    position: absolute;
    top: 25%;
    left: 20%;
    height: 50%;
    width: 65%;
  
}

.image-features{
    position: absolute;
    height: 110%;
    width: 100%;
}


.Value2,.Value1, .Value3, .Value4 {
position: absolute;
}

.Value1{
    left: 20%;
    bottom: 45%;
}
.Value2{
    left: 25%;
    bottom: 2%;
}
.Value3{
    top: 12%;
    right: 33%;
}
.Value4{
    bottom: 20%;
    right: 28%;
}



@media (max-width: 600px) {
    .OFC-Features-Container{
        left: 0%;
        top: 30%;
        height: 30%;
        width: 100%;
        object-fit: contain;
    }
    .Value1{
        top: 30%;
        left: 8%;
    }
    .Value2{
        left: 15%;
        bottom: 0%;
    }
    .Value3{
        right: 25%;
    }
    .Value4{
        right: 20%;
        bottom: 5%;
    }
}

.OFC-Box-2{
    margin: 1% 2%;
    border-top: 4px solid #ed1c2c;
    background-color: #f7f8f9;
  
}

.OFC-Box-3{
    display: flex;
    flex-direction: row;

}

.OFC-Box-4{
    margin-top: 1%;
    margin-bottom: 1%;
    display: flex;
    flex-direction: row;
     justify-content: space-between;
     margin-left: 2%;

}
.OFC-Box-5{
    padding: 2%;
    background-color: #ECF0F5;
    margin-top: 0.5%;
    margin-right: 1%;
    margin-bottom: 0.5%;
    
}
.ofc-box-6{
    margin-top: 2%;
}
.AjustableBox{
    height: 11.5rem;
}

@media (max-width: 600px) {
   .OFC-Box-5{
    padding-bottom: 10%;
   }
}
.AdjustableSlider3{
    height: 13.5rem;
}
.OFC-Slider-Box{
    margin-right: 3%;
    background-color: #ECF0F5;
    padding: 2%;
}
.TT-Box{
    padding: 11.8rem 1% 0  1%;
}

@media (min-height: 720px) {
    .ofc-page-box{
        margin: 2% 2%;
    }
    .OFC-Box-2{
        margin: 2% 2%;
       
    }
    .AjustableBox{
        height: 18rem;
    }
    .AdjustableSlider3{
        height: 19.5rem;
    }
    .TT-Box{
        padding-top: 17.5rem;
    }
    .OFC-Box-5{
        padding-top: 3%;
    }
    .ofc-box-6{
        margin-top: 8%;
    }
}