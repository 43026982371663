

*{
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.LandingScreen{
    display: flex;
    flex-direction: column;
    min-height: 75%;
    
}

.LandingContainer{
    display: flex;
    flex-direction: column;
    margin: 3%;
}

.LandingContainer1{
    flex-grow: 1;
    min-height: 400px;}


.LandingFooter{
    display: flex;
    justify-content: flex-end;
    padding-top: 1%;
}
.userInfo{
    padding-top: 8%;
}


video{
    height: 100%;
    width: 100%;
}

.HomeBox{
    display: flex;
    flex-direction: column;
    margin: 1% 1.5%;
    border-top: 4px solid #ED1C2C;
    background-color: rgba(238, 240, 242, 0.45);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.20);
    min-height: 70%;
}


.HomeContent{
    margin: 1%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    grid-auto-rows: auto;
    column-gap: 20px;
    row-gap: 20px;
}
@media (min-height: 720px) {
    .HomeBox{
        padding: 1.5% 1% 5% 1%;
    }
}


.TitleBox{
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-color: #F2F4F7;
    margin: 1% 1.5% 0 0.5%;
}

.PageTitle{
font-family: 'DM sans';
font-weight: 500;
font-size: 1.3rem;
line-height: 47px;
text-transform: uppercase;
color: #ED1C2C;
}

@media (max-width: 600px) {
    .PageTitle{
        font-size: 0.9rem;
    }
    .userInfo{
        padding: 0%;
    }
    }



