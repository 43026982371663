.hydro-title-box {
  padding: 1% 2% 1% 2%;
}

.image-pibv-box {
  display: flex;
  justify-content: center;
  background-color: black;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: 1px solid #000000;
  border-radius: 5px;
}

.image-container1 {
  position: relative;
  border-radius: 6px;
  background-color: #ECF0F5;
  height: 100%;
  object-fit: contain;
}

.power-image {
  margin-left: 10%;
  position: relative;
  width: 80%;
  transform: rotate(1deg);
}

.GMS {
  position: absolute;
  z-index: 3;
  width: 80%;
}

.Typo6,
.Typo7,
.Typo8,
.LeftRpm {
  position: absolute;
  z-index: 2;
}

.Typo6 {
  left: 5%;
  bottom: 40%;
}

.Typo7 {
  bottom: 2%;
  left: 8%;
}
.Typo8 {
  left: 3%;
  bottom: 20%;
}

.by-pass-valve {
  position: absolute;
  z-index: 2;
  width: 7rem;
  top: 3%;
  left: 13%;
}

.DriveTest1 {
  position: absolute;
  z-index: 1;
  height: 130%;
  left: 130%;
  bottom: -10%;
}
.DriveTest2 {
  position: absolute;
  z-index: 1;
  height: 130%;
  left: 130%;
  bottom: -10%;
}

.LeftRpm {
  top: 40%;
  left: 32%;
}

.RightRpm {
  position: absolute;
  z-index: 2;
  top: 31%;
  right: 22%;
}

@media (max-width: 600px) {
  
  .Typo6 {
    top: 22%;
  }
  .Typo8 {
    top: 35%;
    left: 2%;
  }
  .Typo7 {
    top: 45%;
  }

  .image-container1 {
    height: 300px;
  }

  .by-pass-valve {
    position: relative;
    top: 15%;
    left: 10%;
  }
  .LeftRpm {
    width: 20%;
    position: relative;
    top: -8%;
    left: 42%;
  }
  .RightRpm {
    width: 20%;
    position: relative;
    left: 70%;
    top: -35%;
  }
  .DriveTest1 {
    display: none;
  }
  .DriveTest2 {
    display: none;
  }
}

.Hydro-Box {
  padding: 1% 1%;
  display: flex;
  flex-direction: column;
  margin: 0 1.8% 1.8% 1.8%;
  border-top: 4px solid #ed1c2c;
  background-color: rgba(238, 240, 242, 0.45);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  min-height: auto;
}

.AdjustableSlider2{
  height: 10rem;
}

.Hydro-slider-box1 {
  padding: 1% 4%;
  background-color: #ECF0F5;
  border-radius: 6px;
  margin-bottom: 2%;
}
.Hydro-slider-box2{
  display: flex;
  flex-direction: row;
  padding: 2% 4%;
  background-color: #ECF0F5;
  border-radius: 6px;
}

.Hydro-Box-3{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: 0 4%;
}
.Hydro-Box-3-1{
  margin-bottom: 3%;
}



@media (max-width: 600px){
  .Hydro-Box-3{
    margin: 0%;
  }
}
.Hydro-Box-4{
  padding: 4%;
  background-color: #ECF0F5;
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
}

.Hydro-Box-5{
  padding: 3% 10%;
  background-color: #ECF0F5;
  margin-bottom: 2%;
}
.Hydro-Box-6{
  padding: 2% 10%;
  background-color: #ECF0F5;
 
}

@media (min-height: 720px) {
 .AdjustableSlider2{
  height: 13.2rem;
 }
 .Hydro-Box{
  margin-top: 1%;
  padding: 2% 1%;
 }
 .Hydro-slider-box1{
  padding: 2% 4%;
  margin-bottom: 3%;
 }
 .Hydro-slider-box2{
  padding: 4%;
 }
 .Hydro-Box-3-1{
  margin-bottom: 4%;
  padding: 6% 0%;
}
.Hydro-Box-3-2{
  padding: 6% 0%;
}
.Hydro-Box-4{
  padding: 5% 5% 8% 5%;
}
.Hydro-Box-5{
  padding: 3% 10%;
}

}



