.card {
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 250px;
    overflow: hidden;
    background-color: #F0F2F2;
    
}

.image-container {
    display: flex;
    justify-content: center;

  
}
.card__image--half {
    padding-top: 10px;
    height: 110px;
    padding: 0;
    margin: 0;
    object-fit: contain;
}


.card__image {
    padding-top: 10px;
    width: 100%;
    height: 105px;
    object-fit: contain;
}


.card__content {
    padding: 20px;
}

.card__title {
 
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #ED1C2C;

}

.card__description {
    padding-right: 10%;
    font-size: 15px;
    margin-bottom: 5px;
}

.card__arrow {
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
}

.card__arrow-outer {
    width: 40px; /* Adjust as needed */
    height: 40px; /* Adjust as needed */
}

.card__arrow-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px; /* Adjust as needed */
    height: 15px; /* Adjust as needed */
    transform: translate(-50%, -50%);
}
