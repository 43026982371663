.ChillerBox {
  margin: 1% 1% 1% 1%;
  border-top: 4px solid #ed1c2c;
  padding: 0.5%;
  min-height: 75svh;
  display: flex;
  flex-direction: column;
  background-color: rgba(238, 240, 242, 0.45);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.ChillerBoxA {
  padding-top: 1%;
}


.ChillerSlider {
  padding: 4% 4%;
  background-color: #ecf0f5;
  border-radius: 6px;
  margin-bottom: 4%;
}

.SliderBox2 {
  display: flex;
  flex-direction: column;
  background-color: #ecf0f5;
  border-radius: 6px;
  padding: 6% 5%;
  
}

.sliderItem {
  margin-bottom: 4%; 
}
.chillerBox3,
.chillerBox4 {
  display: flex;
  flex-direction: column;
  background-color: #ecf0f5;
  border-radius: 6px;
  padding: 5% 8% 4% 8%;
}
.chillerBox3 {
  margin: 0 4% 4% 4%;
}

.chillerBox4 {
    margin: 5% 4% 4% 4%;
    padding-bottom: 12%;
  }
  
.resultitem{
    margin-bottom: 7%;
}

.NavButton {
    width: 100%;
    padding: 4px;
    border-radius: 7px;
    margin-top: 1%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  
  
  .chillerBox5 {
    display: flex;
    flex-direction: column;
    background-color: #ecf0f5;
    border-radius: 6px;
    padding: 3.2% 0%;
    justify-content: center;
    align-items: center;
  }
  
  .chillerBox6_1 ,.chillerBox6_2{
    margin-top: 8%;
    padding: 20.5% 0;
    border-radius: 6px;
    background-color: #ecf0f5;
  }
  .gapBox{
    height: 10px;
  }
  
  .imageBox {
    height: 28%;
    width: 28%;
    padding: 2%;
    justify-content: center;
    background-color: #ed1c2c;
  }

  .imageBox2{
    height: 45%;
    width: 24%;
    padding: 2%;
    justify-content: center;
    background-color: #ed1c2c;
  }
  .chillerBox7 {
    min-height: 320px;
    display: flex;
    flex-direction: row;
    margin-left: 4%;
    padding: 10% 10% 10% 0;
    justify-content: space-between;
    border-radius: 6px;
    background-color: #ecf0f5;
  }
  
  .AdjustableSlider{
    height: 18.5rem;
  }
  @media (max-width: 950px) {
    .chillerBox7 {
      display: flex;
      flex-direction: column;
      min-height: 40px;
      width: auto;
      padding: 2% 5%;
    }
    .imageBox{
      
      height: 20%;
      width: 20%;
    }
    .imageBox2{
      height: 20%;
      width: 20%;
    }
    .compare-Chiller-Roi-Box{
      justify-content: space-between;
      padding: 0% 5%;
    }
    .chillerBox5{
      margin-top: 4%;
      padding: 5% 0;
    }
    .chillerBox4{
      margin: 1% 4%;
    }
  
  }

@media (min-height: 720px) {
  .ChillerBox {
    margin-top: 1.5%;
  }

  .ChillerBoxA {
    padding-top: 2.5%;
  }
  .SliderBox2 {
    padding: 8% 5% 5% 5%;
    margin-bottom: 1%;
  }
  .sliderItem {
    margin-bottom: 10%;
  }
  .resultitem{
    margin-bottom: 10%;
}
.chillerBox3{
    padding: 7% 8% 4% 8%;
}
.chillerBox4{
    padding: 9% 8% 10% 8%;
}
.imageBox {
    height: 40%;
    width: 40%;
    padding: 2% ;
  }
  .chillerBox6_1 ,.chillerBox6_2{
    padding: 27.5% 0;
  }
  .chillerBox7{
    padding: 20% 10%;
  }
  .AdjustableSlider{
    height: 20.5rem ;
  }
}





.Compare-Chiller-Box-2 {
  border-top: 4px solid #ed1c2c;
  background-color: #ecf0f5;
  padding: 1% 0%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

@media (min-height: 720px) {
  .Compare-Chiller-Box-1 {
    min-height: 68svh;
    margin-top: 1%;
    height: auto;
  }
  .Compare-Chiller-Box-2 {
    padding: 1.5% 0;
  }
  .custom-check-box-appbar{
    margin-top: 1%;
  }
}
